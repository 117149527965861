import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { BiMapPin, BiRepost } from 'react-icons/bi';
import { useCtx } from '../../context';

import MenuButton from './MenuButton';
import BackgroundBlur from './BackgroundBlur';

import './menu.scss';

const Menu = (): JSX.Element => {
  const { menuOpen, setMenuOpen, traccarToken } = useCtx();
  const wrapperRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && menuOpen) {
        setMenuOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <MenuButton />
      <div className={`menu ${menuOpen ? 'open' : 'closed'}`} ref={wrapperRef}>
        <div className='menu__content'>
          <NavLink to={`/?token=${traccarToken}`} className='menu__row' onClick={toggleMenu} exact>
            <BiMapPin className='menu-icon' />
            <p className='text'>Kartta</p>
          </NavLink>
          <p className='sub-title'>Raportit</p>
          <NavLink to={`/routes?token=${traccarToken}`} className='menu__row' onClick={toggleMenu}>
            <BiRepost className='menu-icon' />
            <p className='text'>Reitit</p>
          </NavLink>
        </div>
      </div>
      <BackgroundBlur />
    </>
  );
};

export default Menu;
