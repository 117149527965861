import React from 'react';
import { useCtx } from '../../../context';

import './backgroundBlur.scss';

const BackgroundBlur = (): JSX.Element => {
  const { menuOpen } = useCtx();
  if (menuOpen) {
    return <div className='blur' />;
  } else return <></>;
};

export default BackgroundBlur;
