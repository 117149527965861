import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { ToastsStore } from 'react-toasts';

import { BiChevronDown } from 'react-icons/bi';
import { useCtx } from '../../../context';

import './replayForm.scss';

const timeOptions = [
  // { name: 'Today', value: 'today' },
  // { name: 'Yesterday', value: 'yesterday' },
  // { name: 'This week', value: 'thisWeek' },
  // { name: 'Previous week', value: 'previousWeek' },
  // { name: 'This month', value: 'thisMonth' },
  // { name: 'This year', value: 'thisYear' },
  { name: 'Päivämäärä', value: 'date' },
  { name: 'Aikaväli', value: 'custom' },
];

const getFromTo = (period: any, from: any, to: any) => {
  let selectedFrom = dayjs();
  let selectedTo = dayjs();

  switch (period.value) {
    case 'today':
      selectedFrom = selectedFrom.startOf('day');
      selectedTo = selectedTo.endOf('day');
      break;
    case 'yesterday':
      selectedFrom = selectedFrom.subtract(1, 'day').startOf('day');
      selectedTo = selectedTo.subtract(1, 'day').endOf('day');
      break;
    case 'thisWeek':
      selectedFrom = selectedFrom.startOf('week');
      selectedTo = selectedTo.endOf('week');
      break;
    case 'previousWeek':
      selectedFrom = selectedFrom.subtract(1, 'week').startOf('week');
      selectedTo = selectedTo.subtract(1, 'week').endOf('week');
      break;
    case 'thisMonth':
      selectedFrom = selectedFrom.startOf('month');
      selectedTo = dayjs().endOf('month');
      break;
    case 'previousMonth':
      selectedFrom = selectedTo.subtract(1, 'month').startOf('month');
      selectedTo = selectedTo.subtract(1, 'month').endOf('month');
      break;
    case 'thisYear':
      selectedFrom = selectedFrom.startOf('year');
      selectedTo = dayjs().endOf('year');
      break;
    case 'date':
      selectedFrom = from ? dayjs(from).startOf('day') : dayjs();
      selectedTo = from ? dayjs(from).endOf('day') : dayjs().endOf('day');
      break;
    default:
      selectedFrom = from ? dayjs(from) : dayjs();
      selectedTo = to ? dayjs(to) : dayjs();
      break;
  }

  return { from: selectedFrom.format(), to: selectedTo.format() };
};

const ReplayForm = ({ onSubmit }: any): JSX.Element => {
  const [deviceSelectOpen, setDeviceSelectOpen] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [timeSelectOpen, setTimeSelectOpen] = useState<boolean>(false);
  const [buffering, setBuffering] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState(timeOptions[0]);

  const { allDevices } = useCtx();

  const deviceRef = React.useRef<HTMLInputElement>(null);
  const timeRef = React.useRef<HTMLInputElement>(null);
  const dateFromRef = React.useRef<HTMLInputElement>(null);
  const dateToRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (deviceRef.current && !deviceRef.current.contains(event.target) && deviceSelectOpen) {
        setDeviceSelectOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deviceRef, deviceSelectOpen]);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (timeRef.current && !timeRef.current.contains(event.target) && timeSelectOpen) {
        setTimeSelectOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deviceRef, timeSelectOpen]);

  const sortDevicesByName = (d: any) => d.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

  // Click handlerers
  const handleDeviceOpen = (): void => {
    setDeviceSelectOpen(!deviceSelectOpen);
    if (timeSelectOpen) setTimeSelectOpen(!timeSelectOpen);
  };

  const handleTimeOpen = (): void => {
    setTimeSelectOpen(!timeSelectOpen);
    if (deviceSelectOpen) setDeviceSelectOpen(!deviceSelectOpen);
  };

  const handleDeviceSelect = (device: any): void => {
    setSelectedDevice(device);
    setDeviceSelectOpen(!deviceSelectOpen);
  };

  const handleTimeSelect = (time: any): void => {
    setSelectedTime(time);
    setTimeSelectOpen(!timeSelectOpen);
  };

  const handleSubmit = async () => {
    if (
      selectedTime.value === 'custom' &&
      (!dateFromRef.current?.value || !dateToRef.current?.value)
    ) {
      ToastsStore.error('Aseta oikea aikaväli!');
      return;
    } else if (selectedTime.value === 'date' && !dateFromRef.current?.value) {
      ToastsStore.error('Valitse oikea päivämäärä');
      return;
    }

    setBuffering(true);
    const { from, to } = getFromTo(
      selectedTime,
      dateFromRef.current?.value,
      dateToRef.current?.value
    );
    await onSubmit(selectedDevice, from, to);
    setBuffering(false);
  };

  return (
    <div className='route-menu'>
      {/* Device select */}
      <div className='input-row'>
        <p className='input-label'>Ajoneuvo</p>
        <div ref={deviceRef} className='device-select dropdown-wrap'>
          <div className='dropdown' onClick={handleDeviceOpen}>
            <p
              className={`dropdown__selected ${
                !selectedDevice ? 'dropdown__selected--placeholder' : ''
              }`}
            >
              {selectedDevice ? selectedDevice?.name : 'Valitse ajoneuvo'}
            </p>
            <BiChevronDown className={`dropdown__icon`} />
          </div>
          {deviceSelectOpen ? (
            <div ref={deviceRef} className='dropdown__select device'>
              <div className='dropdown__content'>
                {sortDevicesByName(allDevices).map((d: any, i: number) => {
                  return (
                    <div key={`dropdown-device-${i}`} className='option'>
                      <p className='option__text' onClick={() => handleDeviceSelect(d)}>
                        {d.name} ({d.attributes?.nickname || ''})
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className='input-row'>
        <p className='input-label'>Jakso</p>
        <div ref={timeRef} className='time-select  dropdown-wrap'>
          <div className='dropdown' onClick={handleTimeOpen}>
            <p className='dropdown__selected'>{selectedTime.name}</p>
            <BiChevronDown className={`dropdown__icon`} />
          </div>
          {timeSelectOpen ? (
            <div ref={timeRef} className='dropdown__select time'>
              <div className='dropdown__content'>
                {timeOptions.map((o: any, i: number) => {
                  return (
                    <div key={`dropdown-time-${i}`} className='option'>
                      <p className='option__text' onClick={() => handleTimeSelect(o)}>
                        {o.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {selectedTime.value === 'date' ? (
        <div className='input-row'>
          <div className='custom-time-select'>
            <div className='input-row'>
              <p className='input-label input-label--small'>Valitse päivämäärä</p>
              <input
                ref={dateFromRef}
                defaultValue={dayjs().format('YYYY-MM-DD')}
                type='date'
                className='date-input'
              />
            </div>
          </div>
        </div>
      ) : null}

      {selectedTime.value === 'custom' ? (
        <div className='input-row'>
          <div className='custom-time-select'>
            <div className='input-row'>
              <p className='input-label input-label--small'>Mistä</p>
              <input ref={dateFromRef} type='datetime-local' className='date-input' />
            </div>
            <div className='input-row'>
              <p className='input-label input-label--small'>Minne</p>
              <input ref={dateToRef} type='datetime-local' className='date-input' />
            </div>
          </div>
        </div>
      ) : null}

      <div className='show-container'>
        <button
          onClick={handleSubmit}
          className='btn btn--primary btn-show'
          disabled={!selectedDevice || buffering ? true : false}
        >
          {buffering ? 'Ladataan...' : 'Näytä'}
        </button>
      </div>
    </div>
  );
};

export default ReplayForm;
