import React from 'react';

import DeviceList from '../DeviceList/index';
import Map from '../Map/index';
import Sidebar from '../Sidebar';

import './mainPage.scss';

const MainPage = (): JSX.Element => {
  return (
    <div className='main-page'>
      <Sidebar>
        <DeviceList />
      </Sidebar>
      <div className='map-container'>
        <Map />
      </div>
    </div>
  );
};

export default MainPage;
