import React from 'react';
import { BiCaretLeft } from 'react-icons/bi';
import { useCtx } from '../../context';
import './sidebar.scss';

function SidebarToggleButton({
  onClick,
  isSidebarOpen,
}: {
  onClick: () => void;
  isSidebarOpen: boolean;
}): JSX.Element {
  return (
    <button
      type='button'
      className={`sidebar-toggle-button ${
        !isSidebarOpen ? 'sidebar-toggle-button--sidebar-closed' : ''
      }`}
      onClick={onClick}
    >
      <BiCaretLeft />
    </button>
  );
}

const Sidebar = ({ children }: any): JSX.Element => {
  const { map, sidebarOpen, setSidebarOpen } = useCtx();

  const onToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  React.useEffect(() => {
    map?.invalidateSize(true);
  }, [sidebarOpen]);

  return (
    <div className={`sidebar ${!sidebarOpen ? 'sidebar--closed' : ''}`}>
      <SidebarToggleButton onClick={onToggle} isSidebarOpen={sidebarOpen} />
      <div className='sidebar__container'>{children}</div>
    </div>
  );
};

export default Sidebar;
