import React, { useEffect } from 'react';

import L, { FeatureGroup as FGType } from 'leaflet';
import { Marker, Circle, Tooltip, Polyline, FeatureGroup } from 'react-leaflet';
import { useCtx } from '../../../context';

import './marker.scss';
import { Group } from '../../../types';

const MARKER_ICON_SIZE = [25, 25];

const markerIcon = (device: any, color: string | null, group: Group): L.DivIcon => {
  const iconSize = MARKER_ICON_SIZE;

  const options: Record<string, unknown> = {
    className: 'marker',
    html: `<span style="background-color: ${color}" class="circle status--${device.status} vehicle-type--${group?.slug}"></span>`,
    iconSize,
  };
  return L.divIcon(options);
};

const lineLayout = ({ color }: { color: string | null }) => {
  return {
    fill: false,
    stroke: true,
    weight: 4,
    color: color || '#13C387',
    opacity: 1,
    noClip: true,
    interactive: false,
    className: '',
  };
};

const DeviceMarker = ({ device, group, onClick, lastPositions }: any): JSX.Element | null => {
  const markerRef = React.useRef<L.Marker>(null);

  if (!lastPositions) {
    return null;
  }

  const coordinates: any = [lastPositions[0].latitude, lastPositions[0].longitude];

  const handleOnClick = (e: any): void => {
    onClick({
      id: device.id,
      marker: e.target,
      position: coordinates,
    });
  };

  const color =
    group?.attributes?.color && device.status === 'online' ? group?.attributes?.color : null;

  return (
    <>
      <Marker
        ref={markerRef}
        position={coordinates}
        icon={markerIcon(device, color, group)}
        data-device={device}
        eventHandlers={{
          click: handleOnClick,
        }}
      >
        <Tooltip opacity={1} offset={[0, 10]} direction='bottom' className='tooltip' permanent>
          <div className='tooltip__content'>{device?.name}</div>
        </Tooltip>
        {device?.status === 'online' && lastPositions[0] ? (
          <Circle
            interactive={false}
            opacity={0.5}
            fillOpacity={0.1}
            center={coordinates}
            radius={lastPositions[0].accuracy < 250 ? lastPositions[0].accuracy : 250}
            weight={1}
          />
        ) : null}
      </Marker>
      {lastPositions && color ? (
        <Polyline
          {...lineLayout({ color })}
          positions={lastPositions.map((p: any) => [p.latitude, p.longitude])}
        />
      ) : null}
    </>
  );
};

const Markers = (): JSX.Element | null => {
  const { map, devices, lastPositions, positions, findGroupById, openDevicePopup } = useCtx();
  const groupRef = React.useRef<FGType | null>(null);
  const handleClick = ({ id, position }: any) => {
    map.setView(position, 16, { animate: true });
    openDevicePopup(id, position);
  };

  const fitBounds = () => {
    const bounds = groupRef.current?.getBounds();
    if (bounds?.isValid()) {
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (groupRef.current) {
      fitBounds();
    }
  }, [groupRef.current]);

  if (!positions.length) {
    return null;
  }

  return (
    <div className='markers'>
      <FeatureGroup ref={groupRef} pane='markers' eventHandlers={{ add: fitBounds }}>
        {devices.map((device: any) => {
          const group = findGroupById(device.groupId);
          return (
            <DeviceMarker
              key={`marker-${device.id}`}
              onClick={handleClick}
              device={device}
              group={group}
              lastPositions={lastPositions[device.id]}
            />
          );
        })}
      </FeatureGroup>
    </div>
  );
};

export default Markers;
