import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ToastsStore } from 'react-toasts';
import axios from 'axios';

import L from 'leaflet';

import ReplayForm from './ReplayForm';
import Map from '../Map/index';
import Sidebar from '../Sidebar';
import { CircleMarker, useMap } from 'react-leaflet';
import Slider from 'react-slider';

import './replayPage.scss';

import { API_URL } from '../../constants';
const DATE_FORMAT = 'D.M.YYYY HH:mm:ss';

const ReplayMarker = ({ position }: any) => {
  return (
    <CircleMarker
      color='#EB6620'
      fillColor='#EB6620'
      center={[position.latitude, position.longitude]}
    />
  );
};

const ReplayPathMap = ({ positions }: any) => {
  const map = useMap();
  const [geojsonlayer, setLayer] = useState<any>(null);

  // Generate Path
  useEffect(() => {
    if (geojsonlayer) {
      geojsonlayer.remove();
    }

    const coordinates = positions.map((item: any) => [item.longitude, item.latitude]);
    if (coordinates.length) {
      const data: any = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates,
        },
      };

      const layer = L.geoJSON(data, { style: { color: '#EB6620' } }).addTo(map);
      map.fitBounds(layer.getBounds(), { padding: [50, 50] });
      setLayer(layer);
    }
  }, [positions]);

  return null;
};

const ReplayPage = (): JSX.Element => {
  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);

  const handleSubmit = async (device: any, from: any, to: any) => {
    const query = new URLSearchParams({ deviceId: device.id, from, to });
    const response = await axios.get(`${API_URL}/api/positions?${query.toString()}`, {
      headers: { Accept: 'application/json' },
    });
    if (response.data) {
      setIndex(0);
      const data = await response.data;
      setPositions(data);
      if (data.length === 0) {
        ToastsStore.success('Ei dataa...');
      }
    }
  };

  return (
    <div className='main-page'>
      <Sidebar>
        <ReplayForm onSubmit={handleSubmit} />
      </Sidebar>
      <div className='map-container'>
        <Map>
          <ReplayPathMap positions={positions} />
          {positions.length && positions[index] ? (
            <ReplayMarker position={positions[index]} />
          ) : null}
        </Map>
        {positions.length && (
          <div className='replay-slider'>
            <Slider
              onChange={(i) => setIndex(i)}
              max={positions.length - 1}
              marks={false}
              renderThumb={(props, state) => {
                return (
                  <>
                    <span {...props}>
                      <div>
                        <div className='thumb-tooltip' key='thumb-tooltip'>
                          <div>
                            {dayjs((positions[state.valueNow] as any).fixTime).format(DATE_FORMAT)}
                          </div>
                        </div>
                      </div>
                    </span>
                  </>
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReplayPage;
