import React from 'react';

import { BiMenu, BiX } from 'react-icons/bi';
import { useCtx } from '../../context';

export default function MenuButton(): JSX.Element {
  const { menuOpen, setMenuOpen } = useCtx();

  return (
    <div role='button' className='menu-toggle-button' onClick={() => setMenuOpen(!menuOpen)}>
      {menuOpen ? <BiX /> : <BiMenu />}
    </div>
  );
}
