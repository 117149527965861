import React from 'react';
import { useEffect } from 'react';
import * as L from 'leaflet';
import { useMap } from 'react-leaflet';
import 'mapbox-gl-leaflet';

// const DEFAULT_STYLE = `${process.env.PUBLIC_URL}/map-styles/mml.json`;

const DEFAULT_ACCESS_TOKEN = '';
const getStyleUrl = (s: string) => `${process.env.PUBLIC_URL}/map-styles/${s}.json`;

let gl: any = null;
const MapboxGLLayer = ({ style }: { style: string }): any => {
  const map = useMap();
  const [current, setCurrent] = React.useState<string | null>(null);

  // Initialize
  useEffect(() => {
    if (typeof map !== 'undefined') {
      gl = L.mapboxGL({
        style: getStyleUrl(style),
        accessToken: DEFAULT_ACCESS_TOKEN,
      });

      gl.addTo(map);
      setCurrent(style);
    }
  }, []);

  // Handle change
  useEffect(() => {
    if (gl && current && style !== current) {
      gl.getMapboxMap().setStyle(getStyleUrl(style));
      setCurrent(style);
    }
  }, [style]);

  return null;
};

export default MapboxGLLayer;
