import React from 'react';
import ReactDOMServer from 'react-dom/server';

import L, { GeoJSON } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import FeatureCollection from '../../../files/features.json';

import './pois.scss';
import { useCtx } from '../../../context';

const POI_ICON_SIZE = [20, 25];

const poiIcon = ({ properties }: GeoJSON.Feature): L.DivIcon => {
  const options: Record<string, unknown> = {
    className: `poi-icon poi-icon--${properties?.type}`,
    html: ReactDOMServer.renderToString(
      <svg preserveAspectRatio='none' color={'currentColor'} viewBox='0 0 40 50'>
        <path
          height='100%'
          width='100%'
          fill='currentColor'
          d='M34.156 6.154C29.127.874 21.752-1.211 14.832.693 7.912 2.597 2.506 8.196.669 15.366c-1.838 7.17.174 14.81 5.271 20.019L20.048 50l14.108-14.616C37.898 31.508 40 26.251 40 20.77c0-5.482-2.102-10.74-5.844-14.616Z'
        />
      </svg>
    ),
    iconSize: POI_ICON_SIZE,
    iconAnchor: [POI_ICON_SIZE[0] / 2, POI_ICON_SIZE[1]],
  };
  return L.divIcon(options);
};

const POIs = (): JSX.Element => {
  const { traccarToken } = useCtx();

  const handleClick = ({ m }: { m: GeoJSON.Feature }) => {
    console.log(m);
    // if (m.geometry.type === 'Point') {
    //   map.setView([m.geometry.coordinates[1], m.geometry.coordinates[0]], 16, { animate: true });
    // }
  };

  return (
    <div className='markers'>
      {(FeatureCollection as GeoJSON.FeatureCollection).features
        .filter((f: GeoJSON.Feature) => f.properties?.token === traccarToken)
        .map((m: GeoJSON.Feature, i: number) => {
          if (m.geometry.type === 'Point') {
            return (
              <Marker
                key={`poi-${i}`}
                position={[m.geometry.coordinates[1], m.geometry.coordinates[0]]}
                icon={poiIcon(m)}
                eventHandlers={{
                  click: (): void => {
                    handleClick({
                      m,
                    });
                  },
                }}
              >
                <Popup offset={[0, -POI_ICON_SIZE[1]]} className='popup poi-popup'>
                  <h3>{m.properties?.title}</h3>
                  <p>{m.properties?.address}</p>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}
    </div>
  );
};

export default POIs;
