import React from 'react';
import { BiLayer, BiX } from 'react-icons/bi';

type Props = {
  style: string;
  onChange: any;
};

interface MapStyle {
  value: string;
  name: string;
}

const mapStyles: MapStyle[] = [
  {
    value: 'mml',
    name: 'Maanmittauslaitos',
  },
  {
    value: 'osm',
    name: 'OpenStreetMaps',
  },
];

function TileLayerSelect({ style, onChange }: Props): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setExpanded(false);
  };

  React.useEffect(() => {
    function handleClickOutside(event: any): void {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && open) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, open]);

  return (
    <div className='leaflet-top leaflet-right'>
      <div
        ref={wrapperRef}
        className={`leaflet-control-tilelayers leaflet-control ${
          expanded ? 'leaflet-control-tilelayers-expanded' : ''
        }`}
      >
        <a
          onClick={() => setExpanded((current) => !current)}
          className='leaflet-control-tilelayers-toggle'
          href='#'
          title='Layers'
        >
          {expanded ? <BiX /> : <BiLayer />}
        </a>
        <section className='leaflet-control-tilelayers-list'>
          {mapStyles.map((s) => (
            <label key={s.value}>
              <input
                type='radio'
                onChange={handleChange}
                className='leaflet-control-tilelayers-selector'
                name='leaflet-tilelayer-select'
                value={s.value}
                checked={style === s.value}
              />
              <span>{s.name}</span>
            </label>
          ))}
        </section>
      </div>
    </div>
  );
}

export default TileLayerSelect;
