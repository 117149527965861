import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

// import Header from './components/Header/index';
import Menu from './components/Menu/index';
import MainPage from './components/MainPage/index';
import ReplayPage from './components/ReplayPage';

import { AppContextProvider } from './context/index';

import './App.scss';
import { deleteCookie } from './utils';

function useQuery() {
  const { search } = window.location;
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const App = (): JSX.Element => {
  const query = useQuery();
  const token = query.get('token');

  // Delete current JSESSIONID cookie
  deleteCookie('JSESSIONID', '/', null);

  if (!token) {
    return (
      <div
        style={{
          color: '#000',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>Access forbidden</h1>
      </div>
    );
  }

  return (
    <div className='App'>
      <Router>
        <AppContextProvider token={token}>
          <ToastsContainer
            store={ToastsStore}
            position={ToastsContainerPosition.BOTTOM_LEFT}
            lightBackground
          />

          {/* <Header /> */}
          <Menu />
          <Switch>
            <Route exact path='/' component={MainPage} />
            <Route exact path='/routes' component={ReplayPage} />
          </Switch>
        </AppContextProvider>
      </Router>
    </div>
  );
};

export default App;
