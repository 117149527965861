import React from 'react';
import { ValbetDestination, ValbetDriver } from '../../../types';

export const DevicePopupContent = ({
  device,
  driver,
  destination,
}: // equipment,
{
  device: any;
  driver: ValbetDriver;
  destination: ValbetDestination;
  // equipment: ValbetEquipment;
}): JSX.Element => {
  return (
    <div className='marker-popup__content'>
      <div className='info-row'>
        {/* <span className='label'>Nimi:</span> */}
        <span className='value'>
          {device.name} ({device.attributes?.nickname || ''})
        </span>
      </div>
      <div className='info-row'>
        <span className='label'>Kohde:</span>
        {destination ? (
          <div>
            <div className='value'>{destination.company || ''}</div>
            <div className='value'>
              {destination.street_address || ''}, {destination.post_code || ''}{' '}
              {destination.post_office || ''}
            </div>
          </div>
        ) : (
          <span className='value'>-</span>
        )}
      </div>
      <div className='info-row'>
        <span className='label'>Kuljettaja:</span>
        <div className='value'>{driver ? driver.name : '-'}</div>
        <div className='value'>{driver ? driver.phone : '-'}</div>
      </div>

      {/* {equipment ? (
        <div className='info-row'>
          <span className='label'>Ajoneuvo:</span>
          <span className='value'>
            {equipment.brand || ''} {equipment.model || ''}
          </span>
        </div>
      ) : null} */}
    </div>
  );
};

/*
const DevicePopup = ({ id, group, status, popupRef }: any) => {
  const [driver, setDriver] = React.useState([{ id: null, name: '-', attributes: { phone: '-' } }]);
  const [popupOpen, setPopupOpen] = React.useState<boolean>(false);

  useEffectAsync(async () => {
    if (popupOpen) {
      const response = await axios.get(`${API_URL}/api/drivers?deviceId=${id}`);
      if (response.data) {
        const data = await response.data;
        setDriver(data);
      }
    }
  }, [popupOpen]);

  const onPopupOpen = () => {
    setPopupOpen(true);
  };

  const onPopupClose = () => {
    setPopupOpen(false);
  };

  return (
    <Popup
      ref={popupRef}
      minWidth={330}
      maxWidth={330}
      autoClose={false}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      offset={[0, -MARKER_ICON_SIZE[1]]}
      className='popup marker-popup'
    >
      <DevicePopupContent />
    </Popup>
  );
}; 
*/
