import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
import { BiChevronRight } from 'react-icons/bi';

// import { useEffectAsync } from '../../utils/useEffectAsync';
import { useCtx } from '../../context';

import './deviceList.scss';
// import { API_URL } from '../../constants';
import { Group } from '../../types';

const DeviceFilterMenu = (): JSX.Element => {
  const { setSelectedFilter, selectedFilter, groups } = useCtx();
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSelect = (f: Group | null) => {
    setSelectedFilter(f);
    setOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (containerRef.current && !containerRef.current.contains(event.target) && open) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, open]);

  return (
    <div ref={containerRef} className='device-filter-menu'>
      <button onClick={() => setOpen(!open)} className='btn btn--device-filter-menu-open'>
        <span className={`label ${selectedFilter ? 'filter-active' : ''}`}>Suodata:</span>
        <span className='value'>{selectedFilter ? selectedFilter?.name : '- Kaikki -'}</span>
      </button>

      <ul className={`device-filter-menu__list ${open ? 'device-filter-menu__list--open' : ''}`}>
        <li onClick={() => handleSelect(null)} className='filter-item'>
          - Kaikki -
        </li>
        {groups.map((f: Group, i: number) => (
          <li onClick={() => handleSelect(f)} key={i} className='filter-item'>
            <span
              style={{ backgroundColor: f.attributes.color ? f.attributes.color : null }}
              className={`vehicle-type vehicle-type--${f.slug}`}
            ></span>
            <span>{f.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DeviceItem = (device: any): JSX.Element => {
  const { map, findGroupById, openDevicePopup } = useCtx();
  const d = device.device;
  const group = findGroupById(d.groupId);

  const getLayer = () => {
    let layer: any = null;
    map.eachLayer((l: any) => {
      if (l.options['data-device'] && d.id === l.options['data-device'].id) {
        layer = l;
      }
    });
    return layer;
  };

  const handleClick = (): void => {
    const layer = getLayer();
    if (layer) {
      map.setView(layer.getLatLng(), Math.max(16, map.getZoom()), { animate: true });
      openDevicePopup(layer.options['data-device'].id, layer.getLatLng());
    }
  };

  const openToolTip = (): void => {
    const layer = getLayer();
    if (layer) {
      layer?.openTooltip();
      layer._icon?.classList.add('highlight');
    }
  };

  const closeToolTip = (): void => {
    const layer = getLayer();
    if (layer) {
      // layer?.closeTooltip();
      layer._icon?.classList.remove('highlight');
    }
  };

  return (
    <div className='device-item' onMouseEnter={openToolTip} onMouseLeave={closeToolTip}>
      <div
        className={`device-row device-status--${d.status === 'online' ? 'online' : 'offline'}`}
        onClick={handleClick}
      >
        <div
          style={{ backgroundColor: group?.attributes?.color ? group.attributes.color : null }}
          className={`device-type-circle vehicle-type--${group?.slug || 'unknown'}`}
        ></div>
        <div className='device-column'>
          <p className='text'>
            {d.name} ({d.attributes?.nickname || ''})
          </p>
          <p className={`status-text ${d.status === 'online' ? 'online' : 'offline'}`}>
            {d.status === 'online' ? 'online' : 'offline'}
          </p>
        </div>
        <div className='options-container'>
          <BiChevronRight />
        </div>
      </div>
      <hr className='line' />
    </div>
  );
};

const DeviceList = (): JSX.Element => {
  const { devices } = useCtx();

  const sortByName = (a: any, b: any) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  };

  const sortByStatus = (d: any) => {
    return [
      ...d.filter(({ status }: any) => status === 'online').sort(sortByName),
      ...d.filter(({ status }: any) => status !== 'online').sort(sortByName),
    ];
  };

  return (
    <>
      <DeviceFilterMenu />
      <div className='device-list'>
        {devices !== null && !devices.length ? (
          <p className='list-empty-text'>Ei ajoneuvoja</p>
        ) : (
          sortByStatus(devices).map((device: any, i: number): JSX.Element => {
            return <DeviceItem key={`device-${i}`} device={device} />;
          })
        )}
      </div>
    </>
  );
};

export default DeviceList;
