import React from 'react';
import Markers from './Markers';
import POIs from './POIs/pois';
import { useCtx } from '../../context';

import './map.scss';

const MapView = (): JSX.Element | null => {
  const { devices } = useCtx();
  if (devices === null) {
    return null;
  }

  return (
    <>
      <Markers />
      <POIs />
    </>
  );
};

export default MapView;
