export const API_URL = `${window.location.protocol}//${
  process.env.NODE_ENV === 'development'
    ? window.location.host
    : process.env.REACT_APP_TRACCAR_API_URL
}`;

export const WS_URL = `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${
  process.env.NODE_ENV === 'development'
    ? window.location.host
    : process.env.REACT_APP_TRACCAR_API_URL
}`;
