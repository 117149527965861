export const isEmbed = (): boolean => {
  const urlParams =
    typeof URLSearchParams !== 'undefined' ? new URLSearchParams(window.location.search) : false;
  let embed = urlParams && urlParams.get('embed') !== null;
  // Check is iframe
  if (embed) {
    try {
      embed = window.self !== window.top;
    } catch (e) {
      embed = true;
    }
  }

  return embed;
};

export const isDesktopSafari = (): boolean => {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);
};

export const slugify = (text: string): string =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const getCookie = (name: string): any => {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=');
  });
};

export const deleteCookie = (name: string, path: string, domain: string | null): void => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};
