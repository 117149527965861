import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

import axios from 'axios';
import L, { LatLng } from 'leaflet';

import { ContextType, Group, ValbetDestination, ValbetDriver } from '../types';
import { isEmbed, slugify } from '../utils';
import { useEffectAsync } from '../utils/useEffectAsync';
import { API_URL, WS_URL } from '../constants';
import { ToastsStore } from 'react-toasts';
import { DevicePopupContent } from '../components/Map/DevicePopup';

axios.defaults.withCredentials = true;

// Context wrapper to avoid initial undefined context type
const createCtx = (): readonly [() => ContextType, React.Provider<ContextType | undefined>] => {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  const useCtx = (): ContextType => {
    const c = React.useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  };
  return [useCtx, ctx.Provider] as const;
};

const [useCtx, CtxProvider] = createCtx();

const AppContextProvider = ({ children, token }: any): JSX.Element | null => {
  const [map, setMap] = useState<any>();
  const [embed, setEmbed] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [session, setSession] = useState();
  const [isAuthError, setIsAuthError] = useState(false);

  const [lastPositions, setLastPositions] = useState<any>({});
  const [positions, setPositions] = useState([]);
  const [allDevices, setAllDevices] = useState(null);
  const [devices, setDevices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<Group | null>(null);

  // Find Group by id
  const findGroupById = (id: number) => groups.find((g: any) => g.id === id);

  // Filter Devices
  const filterDevices = (data: any) => {
    if (!selectedFilter || !selectedFilter.id) {
      return data;
    }

    return data.filter((d: any) => d.groupId === selectedFilter.id);
  };

  // Update devices
  const updateDevices = (payload: any) => {
    setDevices((current: any) =>
      current.map((c: any) => payload.find((o: any) => o.id === c.id) || c)
    );
  };

  useEffect(() => {
    setDevices(filterDevices(allDevices));
  }, [selectedFilter]);

  const connectSocket = () => {
    const socket = new WebSocket(`${WS_URL}/api/socket`);

    socket.onopen = async () => {
      await getGroups();
      await getDrivers();
    };

    socket.onclose = () => {
      setTimeout(() => connectSocket(), 60 * 1000);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.positions) {
        // Save last positions to array
        setLastPositions((current: any) => {
          data.positions.forEach((p: any) => {
            // Push to top of stack
            const newPositions = current[p.deviceId] ? [p, ...current[p.deviceId]] : [p];

            // Save only 20 last
            if (newPositions.length >= 20) {
              newPositions.pop();
            }

            current[p.deviceId] = newPositions;
          });

          return current;
        });

        setPositions(data.positions);
      }
      if (data.devices) {
        updateDevices(data.devices);
      }
    };
  };

  const getDrivers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/drivers`);
      if (response.data) {
        const data = await response.data;
        setDrivers(data);
      }
    } catch (error) {
      console.log(error);
      ToastsStore.error('Couldn´t fetch drivers..');
    }
  };

  const getDevices = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/devices`);
      if (response.data) {
        let data = await response.data;
        data = data.filter((de: any) => !de.disabled);

        setDevices(filterDevices(data));
        setAllDevices(data);
      }
    } catch (error) {
      console.log(error);
      ToastsStore.error('Couldn´t fetch devices..');
    }
  };

  const getGroups = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/groups`);
      if (response.data) {
        const data = await response.data.map((g: Group) => {
          g.slug = slugify(g.name);
          return g;
        });
        setGroups(data);
      }
    } catch (error) {
      console.log(error);
      ToastsStore.error('Couldn´t fetch groups..');
    }
  };

  useEffectAsync(async () => {
    if (session) {
      await getDevices();
      connectSocket();
    } else {
      try {
        const response = await axios.get(`${API_URL}/api/session/?token=${token}`);
        if (response.data) {
          console.log('session', response);
          const ses = response.data;
          setSession(ses);
        } else {
          console.log('Failed to create session', response);
          setIsAuthError(true);
        }
      } catch (error) {
        console.log('Failed to create session', error);
        setIsAuthError(true);
      }
    }
  }, [session]);

  // useEffectAsync(async () => {
  //   if (!session) {
  //     try {
  //       const response = await axios.get(`${API_URL}/api/session/?token=${token}`);
  //       if (response.data) {
  //         console.log('session', response);
  //         const ses = response.data;
  //         setSession(ses);
  //       } else {
  //         console.log('Failed to create session', response);
  //         setIsAuthError(true);
  //       }
  //     } catch (error) {
  //       console.log('Failed to create session', error);
  //       setIsAuthError(true);
  //     }
  //   } else {
  //     connectSocket();
  //     await getDrivers();
  //     await getGroups();
  //   }
  // }, [session]);

  useEffect(() => {
    setEmbed(isEmbed());
  }, []);

  const openDevicePopup = async (deviceId: number, position: LatLng) => {
    const device: any = devices.find((d: any) => d.id === deviceId);

    if (device) {
      let driver: ValbetDriver = null;
      // let equipment: ValbetEquipment = null;
      let destination: ValbetDestination = null;
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_VALBET_API_URL}/map/equipment/${device.uniqueId}`,
          { withCredentials: false }
        );
        driver = data.driver;
        // equipment = data.equipment;
        destination = data.destination;
      } catch (e) {
        console.error(e);
      }
      L.popup({
        className: 'popup marker-popup',
        keepInView: false,
        minWidth: 330,
        maxWidth: 330,
        autoClose: true,
        autoPan: false,
        offset: L.point(0, -15),
      })
        .setLatLng(position)
        .setContent(
          ReactDOMServer.renderToStaticMarkup(
            <DevicePopupContent
              device={device}
              // equipment={equipment}
              destination={destination}
              driver={driver}
            />
          )
        )
        .openOn(map);
    }
  };

  if (isAuthError) {
    return (
      <div
        style={{
          color: '#000',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>Access forbidden</h1>
      </div>
    );
  }

  if (allDevices === null) {
    return <div>Lataa...</div>;
  }

  const defaultValue = {
    traccarToken: token,
    map,
    setMap,
    embed,
    menuOpen,
    sidebarOpen,
    setSidebarOpen,
    setMenuOpen,
    positions,
    lastPositions,
    setPositions,
    devices,
    setDevices,
    allDevices,
    setSelectedFilter,
    selectedFilter,
    openDevicePopup,
    drivers,
    setDrivers,
    groups,
    setGroups,
    findGroupById,
  };

  return <CtxProvider value={defaultValue}>{children}</CtxProvider>;
};

export { useCtx, AppContextProvider };
