import React from 'react';
import { LatLngTuple } from 'leaflet';
import { MapContainer, ZoomControl, ScaleControl } from 'react-leaflet';

import { useCtx } from '../../context';
import MapView from './MapView';
import { isDesktopSafari } from '../../utils';

import MapboxGLLayer from './MapboxGLLayer';

import './map.scss';
import TileLayerSelect from './TileLayerSelect';

const defaultLatLng: LatLngTuple = [61.58, 25.43];
const zoom = 7;

const isMobile = window.innerWidth <= 900;

const Watermark = (): JSX.Element => (
  <a
    href='https://www.zoneatlas.com'
    target='_blank'
    rel='noopener noreferrer'
    className='watermark leaflet-bottom'
  >
    ZONEATLAS <span className='hidden-mobile'>interactive maps</span>
  </a>
);

const LeafletMap = ({ children }: any): JSX.Element => {
  const { setMap, embed } = useCtx();
  const [mapStyle, setMapStyle] = React.useState<string>('mml');

  return (
    <MapContainer
      className='leaflet-container main-map'
      center={defaultLatLng}
      preferCanvas={true}
      zoom={zoom}
      zoomControl={false}
      maxZoom={22}
      tap={false}
      scrollWheelZoom={!isMobile ? !embed : true}
      whenCreated={(map: L.Map) => {
        setMap(map);
        if (embed && !isDesktopSafari()) {
          (map as any).gestureHandling.enable();
        }
      }}
    >
      <Watermark />
      <TileLayerSelect onChange={setMapStyle} style={mapStyle} />
      <ScaleControl imperial={false} />
      <MapboxGLLayer style={mapStyle} />
      <ZoomControl position='bottomright' />
      {children || <MapView />}
    </MapContainer>
  );
};

export default LeafletMap;
